import { Box, Container, Heading, Text, useColorMode } from "@chakra-ui/react"
import React, { useEffect, useState } from "react"
import BannerBlepharo from "../components/choc/BannerBlepharo"
import BannerHyaloron from "../components/choc/BannerHyaloron"
import LogoHeadlineTeaserVeo from "../components/choc/LogoHeadlineTeaserVEO"
import QuadTeaser from "../components/choc/QuadTeaser"
import ImgTextModule from "../components/ImgTextModule"
import Layout from "../components/layout/Layout"
import TwoColumnGridBoxesAllgemein from "../components/ThreeColumnGridBoxesAllgemein"
import FadeInAnimation from "../DefinitionComponents/Animation-Components/FadeInAnimation"
const Blank = () => {
  let { toggleColorMode, ColorMode, setColorMode } = useColorMode()
  useEffect(() => {
    setColorMode(colormodeValue)
  })
  const [viewportHeight, setViewportHeight] = useState(0)
  const [pageHeight, setPageHeight] = useState(0)
  useEffect(() => {
    setViewportHeight(window.innerHeight)
    setPageHeight(document.body.clientHeight)
    // console.log('VIEWPORTHEIGHT: ' + window.innerHeight)
    // console.log('PAGEHEIGHT: ' + document.body.clientHeight)
  }, [])
  const [colormodeValue, changeColorModeValue] = useState("light")
  return (
    <Layout
        title={"JadeKlinik | VeoMed"}
        description={"Mund- Kiefer- und Gesichtschirurgie. Der Patient im Mittelpunkt. "}
    >

      <Container variant="layoutContainer" minH="56vh">
        <ImgTextModule
          src={"560014.mp4"}
          mt={4}
          px={{ base: 4, md: 12, lg: 4, xl: 0 }}
          delay={0.3}
          duration={0.5}
          threshold={0.3}
          initialY={10}
          initialX={10}
          video={true}
          h="56vh"
          mediaOpacity={0.7}
          bgColor="brand.gray.vdark"
        >
          <Box p={{ base: 0, lg: "8" }}>
            <FadeInAnimation
              threshold={0.4}
              delay={0.4}
              duration={0.7}
              initialX={0}
              initialY={-10}
            >
              <Text color="brand.tan.vlight" fontSize="xl">
                Formel und Formal
              </Text>
            </FadeInAnimation>
            <FadeInAnimation
              threshold={0.4}
              delay={0.4}
              duration={0.7}
              initialX={0}
              initialY={-10}
            >
              <Text
                color="brand.tan.ulight"
                fontWeight="normal"
                fontSize={{ base: "5xl", lg: "6xl" }}
              >
                Impressum
              </Text>
            </FadeInAnimation>

            <FadeInAnimation
              threshold={0.4}
              delay={1}
              duration={0.7}
              initialX={0}
              initialY={10}
            >
              <Text
                color="brand.tan.vlight"
                mb={24}
                maxW={{ base: "100%", lg: "50%" }}
                fontSize="2xl"
              >
                Das Rechtliche zu Ihrer Kenntnisnahme
              </Text>
            </FadeInAnimation>
          </Box>
        </ImgTextModule>
      </Container>
      <Container variant="layoutContainer" mt={12} maxW="40%">
        <Heading
          fontSize="2xl"
          color="brand.violet.light"
          textAlign={"left"}
          fontWeight="normal"
        >
          Impressum
        </Heading>
        <Text mb={12}>
          VeoMed JadeKlinik
          <br />
          Dr. Jann Voltmann & Kollegen
          <br />
          Ebertstraße 80
          <br />
          26382 Wilhelmshaven
          <br />
          <br />
          Telefon: 04421-42033
          <br />
          Telefax: 04421-44714
          <br />
          <br />
          E-Mail: info@veo-med.com
          <br />
          Homepage: www.veo-med.com
        </Text>
        <Heading
          fontSize="2xl"
          color="brand.violet.light"
          textAlign={"left"}
          fontWeight="normal"
        >
          INHALTLICH VERANTWORTLICH
        </Heading>
        <Text mb={12}>Dr. med. Jann Voltmann</Text>
        <Heading
          fontSize="2xl"
          color="brand.violet.light"
          textAlign={"left"}
          fontWeight="normal"
        >
          BERUFSBEZEICHNUNG
        </Heading>
        <Text mb={12}>
          Dr.med. Jann Voltmann: Facharzt für Mund-, Kiefer- und
          Gesichtschirurgie, Zahnarzt (Berufsbezeichnungen verliehen in der
          Bundesrepublik Deutschland).
        </Text>
        <Heading
          fontSize="2xl"
          color="brand.violet.light"
          textAlign={"left"}
          fontWeight="normal"
        >
          ZUSTÄNDIGE KAMMERN:
        </Heading>
        <Text mb={12}>
          Ärztekammer Niedersachsen Berliner Allee 20 30175 Hannover Internet:
          http://www.aekn.de/ <br />
          Zahnärztekammer Niedersachsen Zeißstraße 11a 30519 Hannover Internet:
          http://www.zkn.de/
          <br />
        </Text>

        <Heading
          fontSize="2xl"
          color="brand.violet.light"
          textAlign={"left"}
          fontWeight="normal"
        >
          {" "}
          ZUSTÄNDIGE AUFSICHTSBEHÖRDEN:{" "}
        </Heading>
        <Text mb={12}>
          Kassenärztliche Vereinigung Niedersachsen (KZVN) Berliner Allee 22
          30175 Hannover Internet: http://www.kvn.de/Startseite/ <br />
          Kassenzahnärztliche Vereinigung Niedersachsen (KZVN) Zeißstraße 11
          30519 Hannover Internet: http://www.kzvn.de/{" "}
        </Text>

        <Heading
          fontSize="2xl"
          color="brand.violet.light"
          textAlign={"left"}
          fontWeight="normal"
        >
          {" "}
          BERUFSRECHTLICHE REGELUNGEN:{" "}
        </Heading>
        <Text mb={12}>
          Es gelten folgende berufsrechtliche Regelungen: Heilberufe
          Kammergesetz Zahnheilkundegesetz Berufsordnung Gebührenordnung für
          Zahnärzte (GOZ) Gebührenordnung für Ärzte (GOÄ) Die oben genannten
          Regelungen sind einsehbar auf der Website der Zahnärztekammer
          Niedersachsen im Menüpunkt „Gesetzliche Grundlagen“ sowie der
          Ärztekammer Niedersachsen im Menüpunkt „Arzt und Recht“.
        </Text>
        <Heading
          fontSize="2xl"
          color="brand.violet.light"
          textAlign={"left"}
          fontWeight="normal"
        >
          VERBRAUCHERSCHLICHTUNGSVERFAHREN
        </Heading>
        <Text mb={12}>
          Im Hinblick auf eine Verpflichtung aus den §§ 36, 37
          Verbraucherstreitbeilegungsgesetz setzen wir unsere Patienten über
          Folgendes in Kenntnis: Unsere Praxis ist nicht zur Teilnahme an einem
          außergerichtlichen Streitbeilegungsverfahren vor einer
          Verbraucherschlichtungsstelle verpflichtet und nimmt auch an einem
          außergerichtlichen Streitbeilegungsverfahren vor einer
          Verbraucherschlichtungsstelle nicht teil.
        </Text>
      </Container>
      <Container variant="layoutContainer" mt={12}>
        <TwoColumnGridBoxesAllgemein />
      </Container>
      <BannerBlepharo />
      <BannerHyaloron />
      <LogoHeadlineTeaserVeo
        headline="Ästhetik mit High-Tech"
        subheadline="Und Verantwortung"
      />
      <QuadTeaser />
    </Layout>
  )
}

export default Blank
